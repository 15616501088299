$primary: #125700;
$secondary: #0b3300;
$tertiary-1: #1290ff;
$tertiary-2: #197ed6;
$dark: #222222;
$light: #ffffff;
$gray: #F3F5FC;
$overlay:rgba($secondary, 0.8);

.cl-light {
    color: $light;
}
.cl-dark {
    color: $dark;
}
.cl-primary {
    color: $primary;
}
.cl-0 {
    color: transparent;
}

.bg-0 {
    background-color: transparent !important;
}
.bg-primary {
    background-color: $primary !important;
}
.bg-secondary {
    background-color: $secondary !important;
}
.bg-tertiary-1 {
    background-color: $tertiary-1 !important;
}
.bg-tertiary-2 {
    background-color: $tertiary-2 !important;
}
.bg-gray {
    background-color: $gray !important;
}