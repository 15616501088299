@import "colors.scss";

html, body {
    color: $dark;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 24px;
    webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

img {
    max-width: 100%;
    transition: all .3s ease-in-out;
}

a {
    color: $tertiary-1;
    transition: all .3s ease-in-out;
    text-decoration: none !important;

    &:hover {
        color: $primary !important;
    }
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-fixed {
    background-attachment: fixed;
}

.overlay {
    position: relative;

    &:before {
        content: '';
        background-color: $overlay;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.form-control-lg {
    padding: .5rem 1rem !important;
    font-size: 1.25rem !important;
    border-radius: .3rem !important;
}

.entry-content {
    font-size: 16px;

    p {
        font-size: 16px;
    }
}

.banner-section {
    color: $light;
    background-size: cover;
    background-position: center;
    padding-top: 8rem;
    padding-bottom: 2rem;
    text-shadow: 5px 5px rgba($dark, 0.1);

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        content: '';
        background: rgb(9,58,144);
        background: linear-gradient(-90deg, rgba(9,58,144,0) 0%, rgba(19,63,168,0.8) 35%, rgba(3,69,156,1) 100%);
    }
}

.socmed {
    i {
        font-size: 14px;
        padding-right: 16px;
        transition: all .3s ease-in-out;
    }

    a {
        &:last-child {
            i {
                padding-right: 0;
            }
        }
    }
}

.topbar {
    background-color: $secondary;
    color: $light;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media (max-width: 768px){
        display: none;
    }

    a, i {
        color: $light;

        &:hover, &:focus {
            color: $tertiary-1 !important;
        }
    }

    .nav-top {
        a {
            color: rgba($light, 0.5);
            display: inline-block;
            padding: 0 .55rem;
        }
    }
}

.navbar {
    background-color: $light;
    transition: all .3s ease-in-out;
    
    @media (min-width:992px){
        padding: 1rem;
    }

    .navbar-nav {
        .nav-link {
            color: $primary;
            font-weight: 500;

            @media (min-width:992px) {
                padding-right: 1rem;
                padding-left: 1rem;
            }

            &:hover {
                color: $tertiary-1 !important;
            }
        }
        .active {
            .nav-link {
                color: $tertiary-1 !important;
            }
        }
        .nav-search {
            padding-right: 0;
        }
    }

    .navbar-toggler {
        padding: 0 .5rem;
        margin-top: .5rem;
    }

    .navbar-toggler-icon {
        i {
            vertical-align: middle;
        }
    }
}

.scrolled {
    box-shadow: 1px 1px 8px rgba($dark, 0.1);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    .topbar {
        display: none;
    }
}

.header {
    .modal-search {
        background-color: rgba($dark, 0.8);
        
        .modal-content {
            background-color: transparent;
            border: 0;
            padding: 0;

            .modal-body {
                border-bottom: 1px solid $light;
            }
        }
        
        button {
            color: $light;
            font-size: 24px;
        }

        .form-control {
            background-color: transparent;
            color: $light;
            font-size: 24px;
            border: 0;
            padding-left: 0;
            padding-right: 0;

            &:focus {
                border: 0;
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.carousel-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;

    // &:before {
    //     content: '';
    //     background-color: $overlay;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    // }

    .carousel-caption {
        bottom: 30%;

        @media (max-width: 480px){
            bottom: 25%;
        }

        h5 {
            font-size: 70px;
            font-size: 700;
            margin-bottom: 2rem;
            @media (max-width: 480px){
                font-size: 28px;
                margin-bottom: 1rem;
            }
        }
        p {
            font-size: 18px;
            line-height: 30px;
            @media (max-width: 480px){
                font-size: 14px;
                line-height: 25px;
            }
        }
        .btn {
            margin-top: 2rem;
            @media (max-width: 480px){
                margin-top: 1rem;
            }
        }
    }
}

.footer {
    background-color: $primary;
    color: $light;
    font-size: 14px;
    padding-top: 4rem;

    .title-footer {
        color: $light;
    }

    a {
        color: $gray;
    }

    .copyright {
        background-color: $secondary;
        margin-top: 3rem;
    }
}

.socmed {
    a {
        color: $gray;
        font-size: 18px;
    }
}

.section {
    
    .sub-title-section {
        color: $tertiary-1;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    .title-section {
        font-size: 36px;
        line-height: 48px;

        @media (max-width: 800px){
            font-size: 36px;
        }

        @media (max-width: 480px){
            font-size: 24px;
            line-height: 30px;
        }
    }
    .badge-section {
        font-size: 16px;
        border-radius: 0;
        margin-bottom: 1rem;
    }
}

.img-about {
    background-position: top center;
}

.features-section {
    position: relative;
    margin-top: -5rem;
    z-index: 1;
    
    .box {
        background-color: $light;
        box-shadow: 0 -6px 24px rgb(10 10 10 / 9%);
        padding: 2rem;
    }

    i {
        font-size: 24px;
        background-color: $gray;
        color: $primary;
        width: 64px;
        height: 64px;
        border-radius: 100%;
        padding-top: 1.4rem;
    }
}

.features-section-2 {
    background-color: $secondary;
    margin-top: 0;

    i {
        background-color: $tertiary-1;
        color: $light;
    }
}

.news-section {
    padding-right: 5rem;
    padding-left: 5rem;

    @media (max-width: 767px) {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}

.faculty-section {
    padding-right: 5rem;
    padding-left: 5rem;

    @media (max-width: 767px) {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .list-group-item {
        border-bottom: 1px solid rgba($light, 0.2);
        padding: 1rem 0;

        a {
            font-size: 18px;
            color: $light;

            &:hover {
                color: $tertiary-1 !important;
            }
        }
    }
}

.col-news {
    .img-news {
        margin-bottom: 1rem;
    }
    .date {
        font-size: 12px;
    }
}

.video-section {
    color: $light;
    padding: 5rem 0;

    @media (min-width: 992px){
        padding: 10rem 0;
    }

    i {
        color: $light;
        font-size: 72px;
    }

    h2 {
        @media (min-width: 992px){
            font-size: 48px;
        }
    }
}

.tabs-section {
    background-color: $gray;

    .nav-pills {
        @media (max-width: 767px){
            .nav-item {
                width: 100%;

                .nav-link {
                    width: 100%;
                }
            }
        }

        .nav-link {
            background-color: rgba($light, 0.5);
            color: $dark;
            font-size: 18px;
            border-radius: 0;
            padding: 1rem 1.5rem;
        }
        .nav-link {
            &.active,
            &:hover {
                background-color: $primary;
                color: $light;
            }
        }
    }

    .tab-content {
        background-color: $light;
    }

    .img-tab {
        @media (max-width: 991px){
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }

    .entry-content-tab {
        @media (max-width: 768px){
            padding: 3rem;
        }
        @media (min-width: 992px){
            padding: 5rem;
        }
    }
}

.online-class-section {
    padding: 5rem 0;

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        content: '';
        background: rgb(9,58,144);
        background: linear-gradient(90deg, rgba(9,58,144,0) 0%, rgba(19,63,168,0.8) 35%, rgba(3,69,156,1) 100%);
    }
}