.cl-light {
  color: #ffffff;
}

.cl-dark {
  color: #222222;
}

.cl-primary {
  color: #125700;
}

.cl-0 {
  color: transparent;
}

.bg-0 {
  background-color: transparent !important;
}

.bg-primary {
  background-color: #125700 !important;
}

.bg-secondary {
  background-color: #0b3300 !important;
}

.bg-tertiary-1 {
  background-color: #1290ff !important;
}

.bg-tertiary-2 {
  background-color: #197ed6 !important;
}

.bg-gray {
  background-color: #F3F5FC !important;
}

html, body {
  color: #222222;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 24px;
  webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

a {
  color: #1290ff;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}
a:hover {
  color: #125700 !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-fixed {
  background-attachment: fixed;
}

.overlay {
  position: relative;
}
.overlay:before {
  content: "";
  background-color: rgba(11, 51, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-control-lg {
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  border-radius: 0.3rem !important;
}

.entry-content {
  font-size: 16px;
}
.entry-content p {
  font-size: 16px;
}

.banner-section {
  color: #ffffff;
  background-size: cover;
  background-position: center;
  padding-top: 8rem;
  padding-bottom: 2rem;
  text-shadow: 5px 5px rgba(34, 34, 34, 0.1);
}
.banner-section:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  content: "";
  background: #093a90;
  background: linear-gradient(-90deg, rgba(9, 58, 144, 0) 0%, rgba(19, 63, 168, 0.8) 35%, #03459c 100%);
}

.socmed i {
  font-size: 14px;
  padding-right: 16px;
  transition: all 0.3s ease-in-out;
}
.socmed a:last-child i {
  padding-right: 0;
}

.topbar {
  background-color: #0b3300;
  color: #ffffff;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (max-width: 768px) {
  .topbar {
    display: none;
  }
}
.topbar a, .topbar i {
  color: #ffffff;
}
.topbar a:hover, .topbar a:focus, .topbar i:hover, .topbar i:focus {
  color: #1290ff !important;
}
.topbar .nav-top a {
  color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  padding: 0 0.55rem;
}

.navbar {
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
  .navbar {
    padding: 1rem;
  }
}
.navbar .navbar-nav .nav-link {
  color: #125700;
  font-weight: 500;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.navbar .navbar-nav .nav-link:hover {
  color: #1290ff !important;
}
.navbar .navbar-nav .active .nav-link {
  color: #1290ff !important;
}
.navbar .navbar-nav .nav-search {
  padding-right: 0;
}
.navbar .navbar-toggler {
  padding: 0 0.5rem;
  margin-top: 0.5rem;
}
.navbar .navbar-toggler-icon i {
  vertical-align: middle;
}

.scrolled {
  box-shadow: 1px 1px 8px rgba(34, 34, 34, 0.1);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.scrolled .topbar {
  display: none;
}

.header .modal-search {
  background-color: rgba(34, 34, 34, 0.8);
}
.header .modal-search .modal-content {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.header .modal-search .modal-content .modal-body {
  border-bottom: 1px solid #ffffff;
}
.header .modal-search button {
  color: #ffffff;
  font-size: 24px;
}
.header .modal-search .form-control {
  background-color: transparent;
  color: #ffffff;
  font-size: 24px;
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
.header .modal-search .form-control:focus {
  border: 0;
  outline: none;
  box-shadow: none;
}

.carousel-item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}
.carousel-item .carousel-caption {
  bottom: 30%;
}
@media (max-width: 480px) {
  .carousel-item .carousel-caption {
    bottom: 25%;
  }
}
.carousel-item .carousel-caption h5 {
  font-size: 70px;
  font-size: 700;
  margin-bottom: 2rem;
}
@media (max-width: 480px) {
  .carousel-item .carousel-caption h5 {
    font-size: 28px;
    margin-bottom: 1rem;
  }
}
.carousel-item .carousel-caption p {
  font-size: 18px;
  line-height: 30px;
}
@media (max-width: 480px) {
  .carousel-item .carousel-caption p {
    font-size: 14px;
    line-height: 25px;
  }
}
.carousel-item .carousel-caption .btn {
  margin-top: 2rem;
}
@media (max-width: 480px) {
  .carousel-item .carousel-caption .btn {
    margin-top: 1rem;
  }
}

.footer {
  background-color: #125700;
  color: #ffffff;
  font-size: 14px;
  padding-top: 4rem;
}
.footer .title-footer {
  color: #ffffff;
}
.footer a {
  color: #F3F5FC;
}
.footer .copyright {
  background-color: #0b3300;
  margin-top: 3rem;
}

.socmed a {
  color: #F3F5FC;
  font-size: 18px;
}

.section .sub-title-section {
  color: #1290ff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.section .title-section {
  font-size: 36px;
  line-height: 48px;
}
@media (max-width: 800px) {
  .section .title-section {
    font-size: 36px;
  }
}
@media (max-width: 480px) {
  .section .title-section {
    font-size: 24px;
    line-height: 30px;
  }
}
.section .badge-section {
  font-size: 16px;
  border-radius: 0;
  margin-bottom: 1rem;
}

.img-about {
  background-position: top center;
}

.features-section {
  position: relative;
  margin-top: -5rem;
  z-index: 1;
}
.features-section .box {
  background-color: #ffffff;
  box-shadow: 0 -6px 24px rgba(10, 10, 10, 0.09);
  padding: 2rem;
}
.features-section i {
  font-size: 24px;
  background-color: #F3F5FC;
  color: #125700;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  padding-top: 1.4rem;
}

.features-section-2 {
  background-color: #0b3300;
  margin-top: 0;
}
.features-section-2 i {
  background-color: #1290ff;
  color: #ffffff;
}

.news-section {
  padding-right: 5rem;
  padding-left: 5rem;
}
@media (max-width: 767px) {
  .news-section {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.faculty-section {
  padding-right: 5rem;
  padding-left: 5rem;
}
@media (max-width: 767px) {
  .faculty-section {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
.faculty-section .list-group-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem 0;
}
.faculty-section .list-group-item a {
  font-size: 18px;
  color: #ffffff;
}
.faculty-section .list-group-item a:hover {
  color: #1290ff !important;
}

.col-news .img-news {
  margin-bottom: 1rem;
}
.col-news .date {
  font-size: 12px;
}

.video-section {
  color: #ffffff;
  padding: 5rem 0;
}
@media (min-width: 992px) {
  .video-section {
    padding: 10rem 0;
  }
}
.video-section i {
  color: #ffffff;
  font-size: 72px;
}
@media (min-width: 992px) {
  .video-section h2 {
    font-size: 48px;
  }
}

.tabs-section {
  background-color: #F3F5FC;
}
@media (max-width: 767px) {
  .tabs-section .nav-pills .nav-item {
    width: 100%;
  }
  .tabs-section .nav-pills .nav-item .nav-link {
    width: 100%;
  }
}
.tabs-section .nav-pills .nav-link {
  background-color: rgba(255, 255, 255, 0.5);
  color: #222222;
  font-size: 18px;
  border-radius: 0;
  padding: 1rem 1.5rem;
}
.tabs-section .nav-pills .nav-link.active, .tabs-section .nav-pills .nav-link:hover {
  background-color: #125700;
  color: #ffffff;
}
.tabs-section .tab-content {
  background-color: #ffffff;
}
@media (max-width: 991px) {
  .tabs-section .img-tab {
    display: none;
  }
}
.tabs-section .img-tab img {
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .tabs-section .entry-content-tab {
    padding: 3rem;
  }
}
@media (min-width: 992px) {
  .tabs-section .entry-content-tab {
    padding: 5rem;
  }
}

.online-class-section {
  padding: 5rem 0;
}
.online-class-section:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  content: "";
  background: #093a90;
  background: linear-gradient(90deg, rgba(9, 58, 144, 0) 0%, rgba(19, 63, 168, 0.8) 35%, #03459c 100%);
}

.cl-light {
  color: #ffffff;
}

.cl-dark {
  color: #222222;
}

.cl-primary {
  color: #125700;
}

.cl-0 {
  color: transparent;
}

.bg-0 {
  background-color: transparent !important;
}

.bg-primary {
  background-color: #125700 !important;
}

.bg-secondary {
  background-color: #0b3300 !important;
}

.bg-tertiary-1 {
  background-color: #1290ff !important;
}

.bg-tertiary-2 {
  background-color: #197ed6 !important;
}

.bg-gray {
  background-color: #F3F5FC !important;
}

.btn {
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border-radius: 6px;
  padding: 16px 32px;
}

.btn-primary {
  background-color: #125700;
  color: #ffffff;
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #0b3300;
  color: #ffffff !important;
}

.btn-secondary {
  background-color: #1290ff;
  color: #ffffff;
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: #197ed6;
  color: #ffffff !important;
}

.gallery-main .fancybox {
  display: block;
  margin-bottom: 30px;
}
.gallery-main .fancybox .thumb {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
}
@media (max-width: 768px) {
  .gallery-main .fancybox .thumb {
    height: 150px;
  }
}
@media (max-width: 480px) {
  .gallery-main .fancybox .thumb {
    height: 120px;
  }
}

.dropdown-toggle.active-dropdown:after {
  transform: rotate(-90deg);
}

.btco-hover-menu .active a,
.btco-hover-menu .active a:focus,
.btco-hover-menu .active a:hover,
.btco-hover-menu li a:hover,
.btco-hover-menu li a:focus,
.navbar > .show > a, .navbar > .show > a:focus, .navbar > .show > a:hover {
  color: #000;
  background: transparent;
  outline: 0;
}

/*submenu style start from here*/
.dropdown-menu {
  padding: 0px 0;
  margin: 0 0 0;
  border: 0px solid transition !important;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/*first level*/
.btco-hover-menu .collapse ul ul > li:hover > a, .navbar .show .dropdown-menu > li > a:focus, .navbar .show .dropdown-menu > li > a:hover {
  background: #fff;
}

/*second level*/
.btco-hover-menu .collapse ul ul ul > li:hover > a {
  background: #fff;
}

/*third level*/
.btco-hover-menu .collapse ul ul, .btco-hover-menu .collapse ul ul.dropdown-menu {
  background: #fff;
}

.btco-hover-menu .collapse ul ul ul, .btco-hover-menu .collapse ul ul ul.dropdown-menu {
  background: #fff;
}

.btco-hover-menu .collapse ul ul ul ul, .btco-hover-menu .collapse ul ul ul ul.dropdown-menu {
  background: #fff;
}

/*Drop-down menu work on hover*/
@media only screen and (max-width: 991px) {
  .btco-hover-menu .show > .dropdown-toggle::after {
    transform: rotate(-90deg);
  }
}
@media only screen and (min-width: 991px) {
  .btco-hover-menu .collapse ul li {
    position: relative;
  }

  .btco-hover-menu .collapse ul li:hover > ul {
    display: block;
  }

  .btco-hover-menu .collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 200px;
    display: none;
  }

  /*******/
  .btco-hover-menu .collapse ul ul li {
    position: relative;
  }

  .btco-hover-menu .collapse ul ul li:hover > ul {
    display: block;
  }

  .btco-hover-menu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 200px;
    display: none;
  }

  /*******/
  .btco-hover-menu .collapse ul ul ul li {
    position: relative;
  }

  .btco-hover-menu .collapse ul ul ul li:hover ul {
    display: block;
  }

  .btco-hover-menu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 200px;
    display: none;
    z-index: 1;
  }
}